import { css } from "@emotion/react"
import React from "react"
import Slider from "./Slider"
interface Props {}

const PC: React.FC<Props> = (props) => {
  return (
    <div
      css={css`
        position: relative;
        .message {
          position: absolute;
          left: 0;
          top: 0;
          padding: 1rem;
          z-index: 10;

          h5,
          h6 {
            padding: 1rem;
            text-transform: capitalize;
            background-color: rgba(0, 0, 0, 0.7);
            color: white;
            margin: 0.2rem;
          }

          h5 {
            display: inline-block;
          }
          h6 {
            padding: 0.5rem;
          }
        }
      `}
    >
      <Slider />
    </div>
  )
}
export default PC

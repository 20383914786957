import { css } from "@emotion/react"
import { Typography } from "@mui/material"
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
interface Props {}

const index: React.FC<Props> = (props) => {
  return (
    <>
      <Swiper
        pagination={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        css={css`
          max-height: 400px;
          img {
            width: 100%;
          }
        `}
      >
        <SwiperSlide>
          <img src="/samples/WechatIMG84.jpeg" className="fit" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/samples/WechatIMG85.jpeg" className="fit" />
        </SwiperSlide>
      </Swiper>

      <div className="message">
        <Typography variant="h6">
          Choose from our extensive selection of Mardi Gras beads and so much more!
        </Typography>
      </div>
    </>
  )
}
export default index

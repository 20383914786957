import { Grid } from "@mui/material"
import { GetStaticProps } from "next"
import React from "react"
import { IDs, useUrlIds } from "src/components/Product/atoms"
import { FeaturedItems, NewItems } from "src/components/Product/Promotion"
import { Mobile as MobileSlider, PC as PCSlider } from "src/components/Slider"
import { useProductLoader } from "src/machines"
import { getIdsFromUrlList } from "src/spreadsheet"
import { isMobile } from "src/utils"
import SwiperCore, { Autoplay, Pagination } from "swiper/core"
SwiperCore.use([Pagination, Autoplay])

interface Props {
  featuredIds: IDs
  newItemIds: IDs
  fetchedAt: string
}

const index: React.FC<Props> = ({ featuredIds, newItemIds, fetchedAt }) => {
  const { state, send } = useProductLoader()

  const latestFeaturedIds = useUrlIds("featured items", featuredIds, new Date(fetchedAt))
  const featuredItems = state.context.vendProducts.filter((vendProduct) =>
    latestFeaturedIds.includes(vendProduct.id)
  )
  const latestNewItemIds = useUrlIds("new items", newItemIds, new Date(fetchedAt))
  const newItems = state.context.vendProducts.filter((vendProduct) =>
    latestNewItemIds.includes(vendProduct.id)
  )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isMobile() ? <MobileSlider /> : <PCSlider />}
        </Grid>

        <Grid item xs={12}>
          <NewItems vendProducts={newItems} />
        </Grid>

        <Grid item xs={12}>
          <FeaturedItems vendProducts={featuredItems} />
        </Grid>
      </Grid>
    </>
  )
}

export const getStaticProps: GetStaticProps<Props> = async ({ params }) => {
  const featuredIds = await getIdsFromUrlList("featured items")
  const newItemIds = await getIdsFromUrlList("new items")

  const oneHour = 60 * 60

  return {
    props: {
      featuredIds,
      newItemIds,
      fetchedAt: new Date().toString(),
    },
    revalidate: oneHour,
  }
}
export default index
